import { Footer, Header, Main } from "./layout"

export const App = () => {
  return (
    <>
    <Header/>
    <Main/>
    <Footer/>
    </>
  )
}